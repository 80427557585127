:root{
  --primary: #232f3e; /*dark background color*/
  --secondary: #151d26; /*Black*/
  --third: #81B29A; 
  --fourth: #1f374d; 
  --fifth: #f2f2f2; /*light text*/
  --bs-primary: #3D405B;
  --bs-blue: #3D405B;
  --bs-btn-bg : #3D405B;

}
.schedule-form {
	min-height: 75vh; 
	max-height: 75vh; 
	overflow-y: scroll; 
}
.stop-list {
	min-height: 75vh;
	max-height: 75vh;
	background-color:  white;
	border-radius:  10px;
	border-style: solid;
	border-width : 0.5px; 
	overflow-y: scroll;
}
.stop-container {
	background-color: white;
	padding: 3%;
	border-style: solid;
	border-color: purple;
	border-width: 0.5px;
	border-radius: 10px;
	margin-left: 3px;
	min-height: 75vh;
	max-height: 75vh;
	overflow-y: clip;
}

.white-second {
	background-color: rgba(1,1,1,0.05); 
	padding: 3%; 
	border-style : solid; 
	border-color: var(--fourth);
	border-width: 0.5px; 
	border-radius: 5px; 
	margin-left : 3px; 
	min-height: 55vh ; 
	max-height: 55vh;
	overflow-y: scroll; 
}

.scrollable-table {
	min-height: 60vh;
	max-height: 60vh;
	padding: 3%;
	background-color: var(--third);
	overflow-y: scroll;
}

.rounds-backdrop {
	background-color: rgba(1, 1, 1, 0.1);
}

.scrollTable{
	min-height: 15vh;
	max-height: 15vh;
	overflow-y: scroll;
}

.main-container-scroll {
	min-height: 90vh; 
	max-height: 90vh; 
	overflow-y: scroll; 
}
.roundsCardContainer {
	max-height: 70vh;
	min-height: 70vh;
	background-color: rgba(1,1,1,0.0);
	margin-bottom: 3vh;
	padding: 1%;
	margin-top: 0.5em;
	overflow-y: scroll;
}

.invoiceScrollMe {
	max-height: 80vh;
	min-height: 80vh;
	margin-top: 2vh;
	background-color: rgba(107,81,179,0.1);
	padding: 5vh;
	overflow-y: scroll;
}
.dark-background {
	background-color: rgba(192,192,192,0.8) !important;
}

.expandable{
	min-height: 40vh !important; 
	max-height: 40vh !important;
}

.ttReport{
	background-color: white;
	padding: 5%; 
}
.scrollableTable{
	max-height: 20vh; 
	min-height: 20vh;
	padding-bottom: 10vh;  
	overflow-y: scroll;
}
.mainBodySection{
	margin-left: 3em; 
	min-width: 60vw;
	max-width: 60vw;
}
.accountDetails{
	padding: 10%; 
	border-radius: 10%; 
	color:	#712b72;
}
.scrollReports{
	max-height: 30vh; 
	min-height: 30vh;
	overflow-y: scroll; 

}
.scrollme{
	max-height: 90vh; 
	min-height: 90vh;
	overflow-y: scroll; 

}
.modal-body{
	background-color: rgba(226,207,242,0.2) !important; 
}
.second-half{
	background-color: rgba(226,207,242,0.9);
	padding-top: 2%; 
	border-radius: 0px;
	min-height: 80vh; 
	max-height:  80vh; 
	max-width: 70vw;
	min-width: 70vw; 
	padding-left:5%;
	padding-right: 5%;
	overflow-y:clip;
	padding-bottom: 50%; 
}

.small-half{
	border-radius: 0px;
	min-height: 80vh; 
	max-height:  80vh; 
	max-width: 20vw;
	min-width: 20vw; 
	overflow-y: scroll;
	margin-left: 3em;
	margin-right: 5em;
}



.redText{
	color: darkred;
	background-color: rgba(163, 124, 130, 0.5) !important;
}

.danger{
	color: red !important;
	background-color: rgba(241, 130, 141, 0.1) !important;
}

.success{
	background-color: rgba(201, 242, 155, 0.5) !important;
}

.calendarCard{
	max-height: 30vh !important; 
	min-height: 30vh !important;
	display: flex; 
	flex-flow: column; 
	justify-content: space-between;
	font-size: 0.8em !important;
}
.calend {
	max-height: 65vh !important;
	min-height: 65vh !important;
}

.scrollable-big{
	min-height: 100vh; 
	max-height: 100vh ;
	overflow-y: scroll;
	padding-bottom: 50%; 
}

.small-body{
	min-height: 20vh !important;
	max-height: 20vh !important;
	overflow-y: scroll;
}

.card{
	margin-bottom: 0.5em;
	margin-right:0.5em;
	margin-left: 0.5em;
}


.servicesContainer {
	min-height: 95vh;
	max-height: 95vh;
	overflow-y: hidden;
}

.servicesCardBody {
	min-height: 14vh !important; 
	max-height: 14vh !important; 
	overflow-y: scroll;
}
.stopCardHeader {
	min-height: 12vh;
	max-height: 12vh;
	overflow-y: scroll;
}
 
.scrollable {
	max-height: 80vh;
	overflow-y: scroll;
	min-width: 100% !important;
	max-width: 100vw;
}
.ttname{
	background-color: #194B4F;
	color: white;
}
.smallInput{
	border-style: solid;
	border-color: black;
	border-radius: 0px;
	padding: 0;
}
.stopCard{
	min-height: 12vh; 
	max-height: 12vh;
	padding-top: 3%; 
	padding-bottom: 3%; 
	border: solid 1px black;
}
.notTested{
	background-color: #ffdcdc;
	
}
.tested{
	background-color: #2d676f;

	color: white;
}	
.cancelled {
	background-color: white; 

}

.testResults{ 
	padding: 2% !important; 
	margin-left: 10px;
	height: 80vh; 
	overflow-y:  scroll; 
}	

.paddedSection{
	padding: 0px;
	width: 100%;   

}

.waterNav{
	padding-left: 30%; 
	background-color: rgba(150,120,130, 0.5);
	min-height: 95vh; 
	max-height: 95vh; 
	display: flex; 
	flex-flow: column nowrap;
}
.row>*{
	padding: 0px; 
}
.scrollable-modal{
	background-color: rgba(109, 112,151,0.0);
	padding: 3%; 
	overflow-y: scroll !important;

}
.modal-fullscreen{
	overflow-y: clip !important;
}
.noPaddingModal{
	background-color: white; 
	max-height: 90vh; 
	min-height: 90vh;
	overflow-y: clip !important;
	padding: 0; 
}
.customerNav{
	background-color: rgba(100, 100,100,0.5); 
	height: 95vh;
	width: 100%;
	margin: 0px; 
	padding-top: 10%; 
	padding-bottom: 10%; 
	padding-left: 0px; 
	padding-right: 0px;
}
.noclass{
	border-style: none;
}

.greenBorder{
	border:  solid green;;

}
.column-flex{
	
	background-color: rgba(100, 100,100,0.5); 
	height: 95vh;
	width: 100%;
	margin: 0px; 
	padding-top: 10%; 
	padding-bottom: 10%; 
	padding-left: 0px; 
	padding-right: 0px;
}
.spaced-content{
	padding-top: 2%; 
}
#scrollCalendar2{
	max-width: 100%; 
	min-width: 100%;
	max-height: 80vh; 
	min-height: 80vh;
	display: flex; 
	flex-flow: row;
	overflow-y: scroll;
	overflow-x: scroll;
	border-style: solid;
	border-radius: 0px;
	border-width: 0.5px;
	border-color:lightgrey;
	background-color: white;

	margin-top: 20px;

	position: relative;
	background-color: white; 
}

.servicesReportColumn{
	min-width: 15vw; 
	max-width: 15vw; 
	height: 100%; 
	padding: 0px; 
	margin:  0px; 
	font-size: 12px;

	background-color: white;
}

.dispatchStop{

	background-color: white;
	margin-left: 0%; 
	overflow: scroll; 
	padding: 2%; 
	border: 1px outset lightgrey;
	margin-bottom: 10px; 
	height: 18vh;
}
.highlight{
	background-color: var(--fourth);
	color: white;
}
.samecity{
	background-color: var(--third);
	color: white;

}
.testerColumn{
	min-width: 8vw; 
	max-width: 8vw; 
	font-size: 12px;

}
#scrollCalendar{
	max-width: 60vw; 
	min-width: 60vw;
	max-height: 50vh; 
	display: flex; 
	flex-flow: row;
	overflow-y: scroll;
	overflow-x: scroll;
	border-style: solid;
	border-color: lightgrey;
	padding: 5%;
	padding-bottom: 20%;
	position: relative;
}


body::-webkit-scrollbar{
	background: transparent;
}
div::-webkit-scrollbar{
	background: transparent;
}
#scrollCalendar::-webkit-scrollbar{
  	background: white;  
   
  }
 #scrollCalendar::-webkit-scrollbar-track{
 	 background: grey;
  }
 #scrollCalendar::-webkit-scrollbar-button{
 	background: var(--primary);
 }
 #scrollCalendar::::-webkit-scrollbar-thumb{
 	background: var(--secondary);
 }

#scrollCalendar2::-webkit-scrollbar{
   
 }

 #scrollCalendar2::-webkit-scrollbar-track{
 	 background: lightgrey;
 	 border: solid thin black;
 	 max-width: 1px !important;
  }
 #scrollCalendar2::-webkit-scrollbar-button{
 	background: var(--primary);
 }
 #scrollCalendar2::-webkit-scrollbar-thumb{
 	background-color: white;
 	border: solid 1px black;
 }



.applicationHeader{
	background-color: #fafafa;
	padding-top: 2%;  
	min-width: 110%; 
	margin-bottom: 5vh;
	border-bottom: 0.5px groove var(--fifth);
}
.applicationCard{
	background-color: white;
	margin-left: 0%; 
	overflow: scroll; 
	padding: 1%; 
	border: 1px outset lightgrey;

	max-width: 80vw;
}
.main-container{
	height: 100vh; 
	min-width: 90vw; 
	max-width: 90vw; 
	overflow: clip;
	background-color: white;
	padding: 3%;
	padding-bottom: 50%;  
	margin: 0; 

}	
.main-container-scrollable{
	height: 100vh; 
	width: 100vw; 
	overflow: scroll;
	background-color: white;
	padding: 3%;
	padding-bottom: 50%;  
	margin: 0; 

}	
.app-container{
	box-shadow: 2px 0px 10px rgba(0, 0, 0, .1);
}
.scrollable{
	min-height: 75vh; 
	max-height: 75vh;
	overflow-y: scroll;
	max-width: 70vw;
	min-width: 70vw;
	padding-bottom: 30%; 
}
.scrollable-list{
	min-height:90vh; 
	max-height: 90vh;
	max-width: 100%;
	overflow-y: scroll;		
	margin-top: 10px;
	background-color: white; 
	border: solid 0.5px lightgrey;
	border-radius: 0px; 
	padding: 0px;
}	

.scrollable-list::-webkit-scrollbar{
 	 scrollbar-color: white;
 	 border: solid 1px lightgrey;
 	 border-radius: 50px; 
 	 width: 12px;
 	 height: 50%; 
  }
  .scrollable-list::-webkit-scrollbar-thumb{
 	 background: var(--third);
 	 border: solid 1px white;
 	 border-radius: 50px; 
  }




#amazon-space-between{
	display: flex;
	flex-flow: column;
	justify-content: space-between ; 
	min-height: 80vh; 
	max-height: 80vh; 

    color:  #545b64;
    font-size: 15px;

    font-weight: 20;
    line-height:  15px;
}
#amazon-left{
	min-height: 96vh;
	max-height: 96vh; 
	position: sticky;
	padding-left: 2%;
	padding-right: 2%;  
	background-color: white;
	border-right: 2px solid lightgrey;

}

 .reviewSection {
	background-color: rgba(1,1,1,0.1.0); 
	color: black;
	padding: 2%; 
	max-width: 20vw;
	min-width: 20vw;
	font-size: 13px;
	border-color: lightgrey;
	text-align: left;
	margin-bottom: 0px !important;
	margin-top: 0px; 
}
.reviewSection .nav-link.active{
	background-color: lightgrey; 
	max-width: 20vw;
	min-width: 20vw;
	padding: 2%; 
	margin-bottom: 0px !important;
}
.reviewSection  p{
	
	margin-bottom: 0px !important;
}


#amazon-body{
	max-height: 100vh; 
	min-height: 100vh; 
	overflow: clip;
}	

#searchMe-amazon{
	margin-top: 3px;
	height: 4vh;
	width: 35vw; 
	border-radius: 0.0px; 
	background-color: var(--secondary); 
	border-color: var(--fourth);
	color: var(--fifth);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
	overflow: visible;
}
#searchMe-amazon::placeholder{
	color: var(--fifth);
}

#amazon-branding{
	font-size: 15px;
	font-width: 3px;
	color: white;
	margin-left: 2%; 
	letter-spacing: 1px;
	transform: scale(1, 1.2);
}
.amazon-toggle{
	max-width: 2vw ;
	min-width: 2vw; 
	height: 2vh; 
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	background-color: var(--third); /* Green */
	border: none;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
	transition: background-color 0.3s ease;

}

#top-bar-amazon{
	min-height: 6vh;
	max-height: 6vh; 
	background-color: var(--primary);
	padding-top: 1%; 
	padding-bottom: 1%; 
	z-index: 800;
	position: sticky;
}

.flex-column-amazon{
	width: 100%; 
	padding: 10%; 
	height: 100vh;
	box-shadow:0px 0px  20px rgba(0, 0, 0, 0.1);

}
.scrollableBoth{
	min-height: 70vh; 
	max-height: 70vh; 
	overflow-y: scroll;
	margin-top: 2vh;
	display: flex; 
	flex-flow: row nowrap;
}

.loggerScroll{
	max-height: 20vh !important; 
	min-height: 20vh !important;
	overflow-y: scroll !important;
}

.dark-background{
	background-color: rgba(1,1,1,0.8) !important;
	z-index: 1055 !important;

}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
  	#amazon-left{
  		min-height: 96vh;
		max-height: 96vh; 
		position: sticky;
		width: 10vw; 
		padding: 1%; 
		border-style-right: solid;
		background-color: white;

  	}
  	.servicesReportColumn{
		min-width: 100vw; 
		max-width: 100vw; 
		height: 100%; 
		padding: 10%; 
		font-size: 18px;
		border: solid 1px grey;
		border-radius: 50px;
		padding: 2%;
		background-color: white;
	}
	 .main-container{
		height: 100vh; 
		width: 100vw; 
		overflow: scroll;
		background-color: white;
		padding: 3%;
		padding-bottom: 50%;  
		margin: 0; 
	}	


  }

  @media only screen and (max-width: 600px)  {

  		#amazon-left{
  		min-height: 96vh;
		max-height: 96vh; 
		position: sticky;
		width: 10vw; 
		padding: 1%; 
		border-style-right: solid;
		background-color: white;

  	}

  	.main-container{
		height: 100vh; 
		width: 100vw; 
		overflow: scroll;
		background-color: white;
		padding-top: 3%;
		padding-bottom: 50%;  
		padding-left: 0;
		padding-right: 0;
		margin: 0; 
	}	

  	.servicesReportColumn{
		min-width: 100vw; 
		max-width: 100vw; 
		height: 100%; 
		padding: 10%; 
		font-size: 18px;
		border: solid 1px grey;
		border-radius: 50px;
		padding: 2%;
		background-color: white;
	}
  }
