:root{
  --primary: #3a5260; 
  --secondary: #476983; 
  --third: #6a9da1; 
  --fourth: #a3dbd4; 
  --fifth: #c7e3d4; 
}

.limitedHeight{
  min-height: 40vh !important; 
  max-height: 40vh !important; 
  overflow: scroll;
  margin-bottom: 10vh;
}
#headerContainer{
  width: 100%; 

}
.centerMe{
  max-width: 30vw;
}

#entireContent{
  min-height: 90vh;
  max-height: 90vh;
  overflow: hidden;
}

#appContent{
  margin-top: 3vh;
  height: 100%;
  overflow: scroll;
  padding-bottom: 50%;
}
#cc{
  margin-top: 0.5%;
  padding-top: 1%; 
  min-width: 80vw;
  max-width: 80vw;  
  margin-left: 5%; 
  min-height: 100vh; 
  max-height: 100vh ;
  overflow: scroll;
  background-color: white !important; /* Change to whatever color you want */
  border-radius: 0 ;
  box-shadow:1px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 500;
}
.noPaddingNoMargin{
  padding: 0px; 
  margin: 0px;
}

#makeittothetop{
  width: 35vw;
  position: relative;
  left: 35px;
  background-color: var(--primary);
  box-shadow:1px 0px 10px rgba(0, 0, 0, 0.5);
  z-index:1030; 
  padding: 2%; 
  border-radius: 5px; 
}

#makeitothetop{

}
.listDisplay{
  min-height: 50vh;
  max-height: 50vh;
  overflow: scroll; 
}

#myTopBar{
 padding-right: 0px;
 padding-left: 2%; 
 position: sticky; 
 height:15vh;

}


.spaceBetween{
  display: flex; 
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%; 
}
#searchBar{
  background-color:  var(--primary); 
  width: 60vw;
  height:100%;  
  display: flex; 
  flex-flow: column nowrap;
  justify-content: center; 
  padding-left: 10%; 
  padding-right: 10%; 
}

.mobileOnly {
  display: none;
}
#toggle{
 display: none;
}

#desktopMenu{
 display: inline;
 max-width: 10vw; 
 min-width: 10vw; 
 
}

.darkback{
  background-color: var(--primary) !important;
  width: 20vw;
  padding: 5%; 
  margin-top: -10px; 
  z-index: 900;
  height: 50%; 
  width: 25vw; 
  color: white !important;
  margin-top: -8px; 
  margin-left: -20px;
  background-color: var(--primary)!important; /* Change to whatever color you want */
  border-radius: 10px 50px / 50px 0px;
  box-shadow:0px 0px  20px rgba(0, 0, 0, 0.5);
}

.flex-column-distribute{
  display: flex;
  flex-flow: column !important;
  justify-content: space-between !important; 
  margin-top: 0.0px;
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 20%; 
  padding-bottom: 50%; 
  height: 90%; 
  width: 10vw; 
  color: white;
  background-color: var(--primary) !important; /* Change to whatever color you want */
  border-radius: 0px 0px 0px 0px; 
  box-shadow:1px 80px  20px rgba(0, 0, 0, 0.0);
}
.flex-column-distribute a{
  color: white;
}

.flex-column-distribute a:hover{
  color: var(--fourth)  !important;
}
.flex-column-distribute a.active{
  color: white !important;
}
.flex-column-distribute a.disabled{
  color: #7E7E7E !important;
}
 #branding{
    padding-right: 0%;
    padding-left: 0%; 
    font-size: 35px !important;
    word-spacing: 2px !important; 
  }
  .desktopOnly{
    display: inline-block;
  }

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    #appContent{
    overflow: scroll; 
    margin-top: 0vh;
    height: 100vh;
  }

  .desktopOnly{
    display: none;
  }

  .mobileOnly {
    display: inline-block;
  }

  #cc{
    padding-top: 2%; 
    height: 100vh; 
    max-width: 100vw;
    min-width: 100vw;
    overflow: scroll;
    margin-left: -3.5vw; 
   
  }

  #entireContent{
    margin-left: 0px;
    width: 100vw; 
    max-height: 90vh;
    min-height: 90vh; 
    overflow: hidden; 
  }
  .mobileMenu{
    background-color: var(--primary);
    margin-left: 0px;
    padding: 5%;
    padding-bottom: 20%; 
    border-radius: 10px 30px;
    min-width: 80vw;
    max-width: 80vw;
  }

  #branding{
    padding-right: 0%;
    padding-left: 0%; 
    font-size: 15px !important;

  }
  #myTopBar{
    padding-right: 2%;
    padding-left: 2%; 
    position: sticky; 
    height: 10vh;
  }
  #toggle{
    display: inline;
    z-index: 9000;
    background-color: #36454F;
    padding: 1%;
    margin-left:20px; 
  }
  #desktopMenu{
    display: none !important;
  }
  .darkback{
    background-color: #36454F !important;
    width: 20vw;
    padding: 5%; 
    margin-top: -10px; 
    z-index: 900;
    height: 10vh; 
    width: 100vw; 
    color: white !important;
    margin-top: -8px; 
    margin-left: 0px;
    background-color: #36454F !important; /* Change to whatever color you want */
    border-radius: 0px; 
    box-shadow:0px 0px  20px rgba(0, 0, 0, 0.5);
  }
  .flex-column-distribute{
      display: flex;
      flex-flow: column !important;
      justify-content: space-between !important; 
      padding: 10%; 
      height: 80vh; 
    
      margin-left: 0;
      color: white !important;
      background-color: #36454F !important; /* Change to whatever color you want */
      border-radius: 5px;
      border-width: 2px;
      border-color: white;
      z-index: 700;
      box-shadow:0px 0px  20px rgba(0, 0, 0, 0.5);
    }

}


@media only screen and (max-width: 600px)  {
  #appContent{
    overflow: scroll; 
    margin-top: 0vh;
    height: 100vh;
  }
  .desktopOnly{
    display: none;
  }
 .mobileOnly {
    display: block;
  }

  #cc{
    padding-top: 2%; 
    height: 100vh; 
    max-width: 100vw;
    min-width: 100vw;
    overflow: scroll;
    margin-left: 0vw; 
   
  }

  #entireContent{
    margin-left: 0px;
    width: 100vw; 
    max-height: 90vh;
    min-height: 90vh; 
    overflow: hidden; 
  }
  .mobileMenu{
    background-color: var(--primary);
    margin-left: 0px;
    padding: 5%;
    padding-bottom: 20%; 
    border-radius: 10px 30px;
    min-width: 80vw;
    max-width: 80vw;
  }

  #branding{
    padding-right: 0%;
    padding-left: 0%; 
    font-size: 9px !important;

  }
  #myTopBar{
    padding-right: 2%;
    padding-left: 2%; 
    position: sticky; 
    height: 10vh;
  }
  #toggle{
    display: inline;
    z-index: 9000;
    background-color: #36454F;
    padding: 1%;
    margin-left:20px; 
  }
  #desktopMenu{
    display: none !important;
  }
  .darkback{
    background-color: #36454F !important;
    width: 20vw;
    padding: 5%; 
    margin-top: -10px; 
    z-index: 900;
    height: 10vh; 
    width: 100vw; 
    color: white !important;
    margin-top: -8px; 
    margin-left: 0px;
    background-color: #36454F !important; /* Change to whatever color you want */
    border-radius: 0px; 
    box-shadow:0px 0px  20px rgba(0, 0, 0, 0.5);
  }
  .flex-column-distribute{
      display: flex;
      flex-flow: column !important;
      justify-content: space-between !important; 
      padding: 10%; 
      height: 80vh; 
    
      margin-left: 0;
      color: white !important;
      background-color: #36454F !important; /* Change to whatever color you want */
      border-radius: 5px;
      border-width: 2px;
      border-color: white;
      z-index: 700;
      box-shadow:0px 0px  20px rgba(0, 0, 0, 0.5);
    }

}

#snapping{
  overflow: scroll;
  scroll-snap-type: y proximity;
}


.scheduleSection{
  overflow: scroll;
  display: flex; 
  flex-flow: column;
  justify-content: center;
  
  margin-bottom: 50%;
  padding-top: 5%; 
  padding-left: 5%;
  padding-right: 5%;
  scroll-snap-align: start;
  background-color: #ffffff; /* Change to whatever color you want */
  box-shadow:0px 0px  10px rgba(0, 0, 0, 0.0);
}

.innerbox{

  padding: 2%; 
  background-color: #ffffff; /* Change to whatever color you want */
  border-radius: 25px; 
  box-shadow:0px 0px  100px rgba(0, 0, 0, 0.0); /* Change values for different effects */

}

#static{
  position: fixed;

}

#staticright{
  position: fixed;
  top: 25vh;
  right: -85vw; 
}

.fixedHeightBigger{
   min-height: 30vh;   
  max-height: 30vh;
  padding: 5%; 
  overflow: scroll; 
  background-color: #ffffff; /* Change to whatever color you want */
  border-radius: 5px; 
  box-shadow:0px 0px  100px rgba(0, 0, 0, 0.1);
}
.fixedHeight{
  min-height: 40vh;   
  max-height: 40vh;
  padding: 2%; 
  overflow: scroll; 
  background-color: #ffffff; /* Change to whatever color you want */
  border-radius: 10px; 
  margin-bottom: 10px;
  box-shadow:0px 0px  10px rgba(0, 0, 0, 0.5);
}

.fixedHeightSmaller{
  min-height: 20vh;
  max-height: 20vh;
  padding: 2%;
  margin-bottom: 2vh;  
  overflow: scroll; 
  background-color: #ffffff; /* Change to whatever color you want */
  border-radius: 5px; 
  box-shadow:0px 0px  100px rgba(0, 0, 0, 0.1);
}

h3{
 text-transform:uppercase;
}

.customerDetails{
  background-color: white;
}
#appWrapper{
  min-height: 50vh; 
  max-width: 80vw;
  padding: 5%; 
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.5);
border-radius: 5px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(145, 145, 145, 0.53);
overflow-y: scroll;
}

.smallTable{
  width:5vw;

}
.dropme{
  min-width: 100vw; 

}
#fixedHeight{
   height:  80vh; 
   overflow-y: scroll;
}

#invoiceContainer{
  height:  80vh; 
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;  
  padding:  10%; 
  border-style: groove;
  border-color: lightblue;
  border-radius: 10px;
  margin-top:  10px;
  margin-bottom: 10px;
}

.selectedItem{
  background-color: "lightgreen" !important;

}
.yellowPending button{
   background-color: #EFDBB2 ;
}

.greenSuccess button{
  background-color: #009732; 
  
}

.fixedHeight{
  height: 55vh;

}
.centerElements{
  display: flex; 
  flex-flow: column;
  justify-content: center; 
  height: 100%; 
  padding-left: 50%; 
  padding-right: 50%; 
}
.paddedRow{
  display: flex; 
  flex-flow: row;
  padding-top: 0%; 
  min-height: 50vh;
}
.greenSuccess{
  color: #0b630f;
}
.redWarning {
  color: red !important;
}
.hidden_row {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear; 
}
#bgbg{
  background-color: white; 
}
#oneHundred{
  width: 100%;
}
#off{
  padding: 2%;
  background-color: white; 
}
#bgbg2{
  background-color: white; 
  display: flex; 
  flex-flow: row;
  padding-left: 10%;
  padding-right: 10%;  
  padding-bottom: 5%; 

}
.spaceAbove{
  margin-top: 1vh;
}
.informationCard{
  height: 30vh; 
  width: 40vw; 
}

.cardFooter{
	margin-top: 3%;
}
.buttonCard{
  height: 10vh;
  width: 30vw;  
  display: flex; 
  flex-flow: column;
  justify-content: center;

}
.bodyRow{
  padding-left: 30%;
  padding-right: 30%; 
  display: flex; 
  flex-flow: row wrap;
  justify-content: center;
}
#root{
	padding: 0; 
	margin: 0;
	width: 100vw;  
	overflow-x: hidden;
}

.mainCol{
	max-width: 70vw; 

}

.mainBody{
  display: flex; 
  flex-flow: column;
  justify-content: center; 
  margin-top: 10vh;
  margin-left: 35vw;
  margin-right: 35vw;
  width:30vw;
  height: 50vh;
}


.react-calendar {
    width: 100%;
    display: flex;
    flex-flow:column;
    justify-content: center;
    height: 70% !important;
    background-color: white;

    border-radius: 10px;

    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    margin-top: 3vh;
    }


    .react-calendar button {
    margin: 0;
    border-style: solid;
    border-width: 0.5px;
    border-color: lightgrey;
    border-radius: 0px;
    }

    .react-calendar__month-view__weekdays {
      height: 20%;
      display: flex; 
      flex-flow: row;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 1vh;
      font-size: 12px !important;
    }
    .makeitGreen {
      color: white;
      background-color: #08a154 !important;
    }

  .makeitEmpty {
      color: white;
      background-color: tan!important;
    }
 
    .react-calendar__navigation {
  
    }

    .react-calendar__month-view__weekdays__weekday--weekend {
      display: none;
    }
    .hideMe {
      display: none !important;
    }

    .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    }
    .react-calendar__tile {
    min-width: 20%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    }
    .react-calendar__tile:disabled {

    }
    .react-calendar__tile--active{
        border-style: solid !important;
        border-color: #B5ED96 !important;
        border-width: 5px !important;
    }


    .react-calendar__month-view__weekdays {
      justify-content:  space-evenly;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    background-color:#f1f1f1;
    }
body{
	padding: 0; 
	margin: 0; 
	width: 100vw;  
	overflow-x: hidden;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: hidden
}

.container-fluid{
	padding: 0; 
	margin: 0; 
	width: 100vw;  
	overflow-x: hidden;
}
@font-face {
  font-family: 'goudyheapregular';
  src: url('./Media/Fonts/goudyHeap/goudyheap-webfont.woff2') format('woff2'),
       url('./Media/Fonts/goudyHeap/goudyheap-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.companyName{
	font-family: 'goudyheapregular';
	padding: 0px;
}

.decorHeader{
	background-color: darkgrey;
	height: 0.3vh;
	width: 100%;   
}	

.headerContainer{
	padding:2%; 

}

.flexEnd{
	display: flex; 
	flex-flow: row;
	justify-content: flex-end
}	

.flexBetween{
	min-width: 100vw;
	display: flex; 
	flex-flow: row; 
	justify-content: space-between;
}
.flexBetweenNoWidth{
  max-width: 100%;
  display: flex; 
  flex-flow: row; 
  justify-content: space-between;
}
.ended{
  display: flex; 
  flex-flow: row; 
  justify-content: flex-end; 
  padding-right: 10%; 
}
  
.spaced{
  display: flex; 
  flex-flow: row;
  justify-content: space-between;
  width: 100vw; 
}
.spaced .col{
  display: flex; 
  flex-flow: row;
  justify-content: center; 
}
.myFoot{
	min-height: 50vh;
	background-color: black;
	margin-top: 20vh;
}

.mainContent{
	display: flex; 
	flex-flow: column;
	min-height: 100vh;
	justify-content: space-between; 

}
.BarBar{
  width: 10vw; 
  font-size: 10pt;
}

.loginBody{
	min-height: 50vh; 
	padding: 10%; 
	padding-left: 35%;
	padding-right: 35%; 
}	
#mymap{
  height: 50vh; 
  width: 100%;
}
.alternativeItem{
  background-color: white;
  color: black;
  padding: 2%;
  width: 100%; 
  display: block;
  border-radius:  10px;
  text-align:  center;
}
.alternativeItem:hover{
  background-color: lightgrey;
  color: black;
  display: block;
}
.alternativeItem:focus{
  background-color: lightgrey;
  color: black;
  display: block;
}

*{padding:0;margin:0}
.wrapper{
  height:50vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:rgba(255, 255,255,0.3)
}
.checkmark__circle{
  stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #7ac142;fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
  }
.checkmark{
  width: 56px;height: 56px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 10% auto;box-shadow: inset 0px 0px 0px #7ac142;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
  }
  .checkmark__check{
    transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}@keyframes stroke{100%{stroke-dashoffset: 0}}@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}
    @keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}}



    .react-calendar {
    width: 100%;
    display: flex;
    flex-flow:column;
    justify-content: center;
    height: 80% !important;
    background-color: white;

    border-radius: 10px;

    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    margin-top: 3vh;
    }


    .react-calendar button {
    margin: 0;
    border-style: solid;
    border-width: 0.5px;
    border-color: lightgrey;
    border-radius: 0px;
    }

    .react-calendar__month-view__weekdays {
      height: 20%;
      display: flex; 
      flex-flow: row;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 1vh;
      font-size: 12px !important;
    }
    .makeitGreen {
      color: white;
      background-color: #08a154 !important;
    }

    .makeitGreen:enabled {
          
    }
    .react-calendar__navigation {
    display: flex;
    justify-content: center;
    }

    .react-calendar__month-view__weekdays__weekday--weekend {
      display: none;
    }
    .hideMe {
      display: none !important;
    }

    .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    }
    .react-calendar__tile {
    min-width: 20%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    }
    .react-calendar__tile:disabled {

    }
    .react-calendar__month-view__weekdays {
      justify-content:  space-evenly;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    background-color:#f1f1f1;
    }